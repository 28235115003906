import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  
  return (

      <div id='centernav' className=' flex justify-between items-center py-4 z-10 md:flex-row flex-col'>
      <div className='pb-4 md:pb-0'>
      <Link to="/">
      <svg
      className="animate-logoreveal stroke-metal-1 opacity-0"
      style={{ height: '60px', strokeLinecap: "round", }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 659.22 642.68"
    >
      <defs>
        <clipPath id="b">
          <path
            d="M561.06,452.73c12.74,12.74,8.91,34.16-7.36,41.89-49.27,23.41-106.98,23.39-156.25,0-16.27-7.73-20.1-29.15-7.37-41.89l67.13-67.13c10.14-10.14,26.58-10.14,36.72,0l67.13,67.13Zm-7.36-286.31c-59.96-28.49-132.43-22.29-186.97,18.55-.44,.32-.88,.68-1.32,1-6.37,4.88-12.51,10.22-18.34,16.05l-146.86,146.86c-11.13,11.13-29.87,10.04-39.5-3.27-7.53-10.4-5.77-24.89,3.31-33.96l103.33-103.33c12.69-12.69,8.97-34.12-7.23-41.83-69.53-33.1-155.9-19.55-211.67,40.63-64.17,69.26-64.17,177.55,0,246.81,64.3,69.4,169.3,76.79,242.25,22.15,6.86-5.13,13.44-10.82,19.66-17.05l250.71-250.71c12.74-12.74,8.91-34.16-7.36-41.89Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#b)">
        <path
          d="M585.71,587.52L255.28,257.09c-40.55-40.55-106.31-40.55-146.86,0-40.55,40.55-40.55,106.31,0,146.86,40.55,40.55,106.31,40.55,146.86,0L604.07,55.15"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="156"
        />
      </g>
    </svg>
    </Link>
      </div>
        <div id='navright' className='flex text-silver-1 gap-5 text-center'>

  <a href="https://www.instagram.com/cannonkissane/" target="_blank" rel="noopener noreferrer" className='animate-fade  py-1.5  border border-silver rounded-lg'>Instagram</a>
  <a href="https://twitter.com/cannonkissane" target="_blank" rel="noopener noreferrer" className='animate-fade py-1.5  border border-silver rounded-lg'>X</a>
  <a href="https://www.linkedin.com/in/cannonkissane/" target="_blank" rel="noopener noreferrer" className='animate-fade  py-1.5  border border-silver rounded-lg'>LinkedIn</a>
  <a href="mailto:kissane@stanford.edu" className='animate-fade px-3 py-1.5 bg-gradient-to-b from-metal-1 to-metal-0 border border-silver-0 rounded-lg text-white'>Contact</a>


      </div>
    </div>

  );
}

export default Navbar;
