import React, { useState } from 'react';

const HoverVideoComponent = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
        {/* Hover case */}
            <div
            className="bg-metal border border-metal-1 rounded-lg justify-center items-center aspect-[16/9] lg:flex hidden"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            <video
                autoPlay
                muted
                loop
                playsInline
                className={`w-full rounded-lg ${isHovered ? 'hidden' : 'block'}`}
            >
                <source src="/img/Pencil4.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <video
                autoPlay
                muted
                loop
                playsInline
                className={`w-full rounded-lg ${isHovered ? 'block' : 'hidden'}`}
            >
                <source src="/img/Pencil3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </div>

            {/* Small case */}
            <div
            className="bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] lg:hidden"
            >
           
            <video
                autoPlay
                muted
                loop
                playsInline
                className={`w-full rounded-lg`}
            >
                <source src="/img/Pencil3.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </div>
    </div>
  );
};

export default HoverVideoComponent;
