import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HoverVideoComponent from './HoverVideo';

function Hero() {
  return (

<div>
  <img src="/img/CannonKissaneDark.svg" alt="Cannon Kissane Text" className='opacity-100 pt-8 md:pt-12 w-full'/>

  <div id='HERO2' className='grid w-full grid-cols-1 md:grid-cols-2 gap-12 py-12'>

  <div className='bg-meta w-full'>
        <div className=' grid grid-cols-1 gap-3'>
          <a href="https://www.linkedin.com/in/cannonkissane/" target="_blank" rel="noopener noreferrer">
          <div className='group bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex justify-center items-center aspect-[16/9] border border-silver-0 border-opacity-100'>
                <img src="/img/cannonpng.png" alt="Cannon Kissane Picture" className='h-full lg:group-hover:scale-[130%] scale-[130%] lg:scale-[%100] transition-all origin-bottom'/>
          </div>
          </a>
          <div className='text-silver-1 font-medium text-xl'>Cannon Kissane</div>
          <div className='text-silver-0 text-md leading-[.5]'>Designer & Coder</div>
        </div>
      </div>

      <a href="mailto:kissane@stanford.edu" target="_blank" rel="noopener noreferrer">
      <div className='bg-meta w-full '>
        <div className='grid grid-cols-1 gap-3'>
          <div className='group text-white bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex flex-col justify-center items-center aspect-[16/9] border border-silver-00'>
          <div className="px-2 text-xl md:text-lg xl:text-xl 2xl:text-2xl font-bold group-hover:bg-none rounded-md lg:bg-none bg-none">Hey there!</div>
          <div className=" px-2 mt-4 md:mt-1 lg:mt-2 text-lg md:text-lg xl:text-lg 2xl:text-xl group-hover:bg-none rounded-md lg:bg-none bg-none">I'm a design engineer at Stanford.</div>
          <div className=" px-2 mt-4 md:mt-1 lg:mt-2 text-lg md:text-lg xl:text-lg 2xl:text-xl group-hover:bg-none rounded-md lg:bg-none bg-none">Let's build something together.</div>

          </div>
          <div className='text-silver-1 font-medium text-xl'>Contact Me</div>
          <div className='text-silver-0 text-md leading-[.5]'>Let's make something.</div>
        </div>
      </div>
      </a>
      
  </div>

    <div id='PORTFOLIO' className='grid w-full grid-cols-1 md:grid-cols-2 gap-12 py-12 border-t border-b border-silver-0'>
        
    <Link to="/portfolio/agihouse">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <div className='group bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex justify-center items-center aspect-[16/9] border border-silver-00'>
              <img src="/img/agihouse.svg" alt="Meaning Logo" className='w-52 group-hover:scale-110 transition-transform'/>
          </div>
          <div className='text-silver-1 font-medium text-xl'>AGI House</div>
          <div className='text-silver-0 text-md leading-[.5]'>Software Engineer and Strategy</div>
        </div>
      </div>
      </Link>

    <Link to="/portfolio/meaning">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
        <div className='group bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex justify-center items-center aspect-[16/9] border border-silver-00'>
              <img src="/img/meaninglogo.svg" alt="Meaning Logo" className='w-52 group-hover:scale-105 transition-transform'/>
          </div>
          <div className='text-silver-1 font-medium text-xl'>Meaning AI</div>
          <div className='text-silver-0 text-md leading-[.5]'>Frontend & Product Design</div>
        </div>
      </div>
      </Link>
        
        <Link to="/portfolio/sendmagic">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
        <div className='group bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex justify-center items-center aspect-[16/9] border border-silver-00'>
              <img src="/img/sendmagic.svg" alt="Send Magic Logo" className='w-52 group-hover:scale-110 transition-transform'/>
          </div>
          <div className='text-silver-1 font-medium text-xl'>SendMagic</div>
          <div className='text-silver-0 text-md leading-[.5]'>Frontend & Product Design</div>
        </div>
      </div>
      </Link>

      <Link to="/portfolio/amongus">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
        <div className='group text-white bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex flex-col justify-center items-center aspect-[16/9] border border-silver-00'>
              <img src="/img/amongus.svg" alt="Send Magic Logo" className='w-52 group-hover:scale-110 transition-transform'/>
          </div>
          <div className='text-silver-1 font-medium text-xl'>Among Us</div>
          <div className='text-silver-0 text-md leading-[.5]'>Merchandise Design</div>
        </div>
      </div>
      </Link>

      <Link to="/portfolio/hypothetic">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
        <div className='group bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex justify-center items-center aspect-[16/9] border border-silver-00'>
              <img src="/img/hypothetic.svg" alt="Send Magic Logo" className='w-28 group-hover:scale-110 transition-transform'/>
          </div>
          <div className='text-silver-1 font-medium text-xl'>Hypothetic</div>
          <div className='text-silver-0 text-md leading-[.5]'>Product Design</div>
        </div>
      </div>
      </Link>

      <Link to="/portfolio/onemint">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
        <div className='group bg-gradient-to-b from-metal-1 to-metal-0 rounded-2xl flex justify-center items-center aspect-[16/9] border border-silver-00'>
              <img src="/img/onemint.svg" alt="Send Magic Logo" className='w-52 group-hover:scale-110 transition-transform'/>
          </div>
          <div className='text-silver-1 font-medium text-xl'>OneMint</div>
          <div className='text-silver-0 text-md leading-[.5]'>Product Design & Media</div>
        </div>
      </div>
      </Link>
      


    </div>

</div>

  );
}

export default Hero;
