import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Hero from './Hero';
import Navbar from './Navbar';
import Portfolio from './Portfolio'; 

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div id='FULLPAGE' className='w-full flex flex-col items-center bg-silver font-onest'>
        <div id='CENTERPAGE' className='w-11/12 lg:w-2/3 max-w-screen-xl'>
          <Navbar />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/portfolio/agihouse" element={<Portfolio project='agihouse'/>} />
            <Route path="/portfolio/meaning" element={<Portfolio project='meaning'/>} />
            <Route path="/portfolio/sendmagic" element={<Portfolio project='sendmagic'/>} />
            <Route path="/portfolio/amongus" element={<Portfolio project='amongus'/>} />
            <Route path="/portfolio/hypothetic" element={<Portfolio project='hypothetic'/>} />
            <Route path="/portfolio/onemint" element={<Portfolio project='onemint'/>} />
          </Routes>


          
          <div className=' border-b border-silver-0 w-full'>
          <Navbar />
          </div>

          <div className='w-full py-4 text-silver-0 text-center'>© Cannon Kissane 2025</div>
          
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
