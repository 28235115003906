import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Portfolio(props) {
  const { project } = props;

  // Define variables for each project scenario
  let projectImages, projectName, projectSubtitle, skills, deliverables, tools, story, projectLink, projectLinkDesc, projectType, projectDate;

  // Set variables
  switch (project) {
    case 'agihouse':
      projectImages = [
        '/img/agihouse1.png',
        '/img/agihouse2.png',
        '/img/agihouse3.png',
      ];
      projectName = 'AGI House';
      projectSubtitle = ' | AI Seed Fund & Founder Community';
      skills = 'Software Engineer and Strategy';
      deliverables = 'Website, Brand, & Platform';
      tools = ['React', 'Tailwind', 'Figma'];
      story =
        'Designed and coded next-generation software systems for venture capital firm, for talent search & screening, company tracking, & events management. Contributed to the success of multiple AI community events including hosting Eric Schmidt (former CEO of Google) & Professor Andrew Ng (Board Member of Amazon). Collaborated with the AGI House founders to establish the AGI Fellowship program & investment thesis.';
        // projectLink = 'https://apps.apple.com/us/app/screen-time-ai-meaning/id6476325504';
        projectLink = 'https://agihouse.org/';
        projectLinkDesc = 'agihouse.org';
        projectDate = '2024';
        projectType = 'Summer Internship';
        break;
    case 'amongus':
      projectImages = [
        '/img/amongusposter1.png',
        '/img/amongusposter2.png',
      ];
      projectName = 'Among Us';
      projectSubtitle = ' | Hit video game by Innersloth';
      skills = 'Merchandise Design';
      deliverables = 'Print Ready Poster';
      tools = ['Illustrator'];
      story =
        'During lockdown I spent countless hours playing Among Us and designing. It only made sense to combine the two. I created a blueprint-style poster featuring the popular map "The Skeld" and cold emailed the developers of the game. After a couple weeks, I finally got a response. They wanted to license and produce the artwork! Today the poster is sold in limited edition physical versions of the game.';
        projectLink = 'https://www.amazon.com/Among-Us-Ejected-Nintendo-Switch/dp/B098YZG612';
        projectLinkDesc = 'Poster On Amazon';
        projectDate = '2021';
        projectType = 'Personal Project';
        break;
    case 'meaning':
      projectImages = [
        '/img/meaning1.png',
        '/img/meaning2.png',
      ];
      projectName = 'Meaning';
      projectSubtitle = ' | Screen Time AI';
      skills = 'Frontend & Product Design';
      deliverables = 'iOS App';
      tools = ['Figma', 'Swift'];
      story =
        'What if you had to convince an AI to be able to use social media? Screen time and social media apps are stealing our lives. And frankly its unfair – Your apps are designed by paid professionals to be as addictive as possible. And current screen time solutions are way too easy to bypass. That’s why I decided to build Meaning - the AI Screen Time Coach. Meaning holds you accountable to your screen time goals - requiring you to justify any deviation from your set limits. How would your life look differently if you had AI holding you accountable?';
        // projectLink = 'https://apps.apple.com/us/app/screen-time-ai-meaning/id6476325504';
        projectLink = '';
        projectLinkDesc = 'Download Now';
        projectDate = '2024';
        projectType = 'Personal Project';
        break;
    case 'amongus':
      projectImages = [
        '/img/amongusposter1.png',
        '/img/amongusposter2.png',
      ];
      projectName = 'Among Us';
      projectSubtitle = ' | Hit video game by Innersloth';
      skills = 'Merchandise Design';
      deliverables = 'Print Ready Poster';
      tools = ['Illustrator'];
      story =
        'During lockdown I spent countless hours playing Among Us and designing. It only made sense to combine the two. I created a blueprint-style poster featuring the popular map "The Skeld" and cold emailed the developers of the game. After a couple weeks, I finally got a response. They wanted to license and produce the artwork! Today the poster is sold in limited edition physical versions of the game.';
        projectLink = 'https://www.amazon.com/Among-Us-Ejected-Nintendo-Switch/dp/B098YZG612';
        projectLinkDesc = 'Poster On Amazon';
        projectDate = '2021';
        projectType = 'Personal Project';
        break;
    case 'onemint':
      projectImages = [
        '/img/nftpreview.mp4',
        '/img/onemintyoutube.png',
        '/img/onemintlogo.png',
      ];
      projectName = 'OneMint';
      projectSubtitle = ' | NFT creator suite';
      skills = 'Product Design & Media';
      deliverables = 'UI/UX, Brand, & Youtube Growth';
      tools = ['Figma', 'Illustrator', 'Premier', 'AfterEffects', 'Youtube', ];
      story =
        'First hire by former Facebook Senior Software Engineer.  Company focused on making NFTs accessible to artists and creators.  Designed and launched new product features for smart contracts. Responsible for community development and branding. Helped grow the company over 10x to 10,000 active daily users in 60 days.';
        projectLink = 'https://onemint.io/';
        projectLinkDesc = 'onemint.io';
        projectDate = '2021';
      projectType = 'Part Time';
        break;

      case 'hypothetic':
      projectImages = [
        '/img/hypotheticpending.png',
      ];
      projectName = 'Hypothetic';
      projectSubtitle = ' | AI for 3D';
      skills = 'Product Design';
      deliverables = 'New Product UI/UX';
      tools = ['Figma',];
      story =
        'Startup revolutionizing 3D work with creative tools, collaboration, and AI. Prototyped and iterated on product interfaces specifically designed for seamless collaboration with AI in 3D. Development in progress.';
        projectLink = 'https://www.hypothetic.art/';
        projectLinkDesc = 'Hypothetic.art';
        projectDate = '2023';
      projectType = 'Internship';
        break;

      case 'sendmagic':
      projectImages = [
        '/img/sendmagicdemo.mp4',
        '/img/sendmagicthumbnail.png',
      ];
      projectName = 'SendMagic';
      projectSubtitle = ' | Email embedded AI';
      skills = 'Frontend & Product Design';
      deliverables = 'Website & Product Interface';
      tools = ['Figma', 'Tailwind', 'React'];
      story = 'The world\'s first email you can have a conversation with. No more unanswered questions or long email chains. Instant response time. Interactive AI chatbots embedded in any email you send to your customers. Custom chatbots with the context of your product, company, and message. Welcome to the next generation of email marketing and customer service. Try it live in your email right now at sendmagic.ai 💌.';
      projectLink = 'https://www.sendmagic.ai/';
      projectLinkDesc = 'View Live';
      projectDate = '2023';
      projectType = 'Personal Project';
        break;
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={className}
      style={{
        ...style,
        display: window.matchMedia('(min-width: 768px)').matches ? 'block' : 'none',
      }}
      onClick={onClick}
    />
    );
  }

  function CustomArrow(props) {
    const { className, style, onClick, direction } = props;
    const isLeft = direction === 'left';
  
    return (
      <div
        className={`${className} custom-arrow`}
        style={{
          ...style,
          display: window.matchMedia('(min-width: 768px)').matches ? 'block' : 'none',
          zIndex: 1,
        }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 text-silver-0"
          style={{ transform: isLeft ? 'rotate(180deg)' : 'none' }}
        >
          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
        </svg>
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
    afterChange: (current) => setCurrentIndex(current),

    customPaging: i => (
      <div
        style={{
          marginTop: '12px',
          width: "8px",
          height: '8px',
          background: currentIndex === i ? 'Gray' : 'lightGray', 
          borderRadius: '8px',
          gap: "10px",
          fontWeight: 'bolder',
        }}
      >
    
      </div>
    ),
    prevArrow: <CustomArrow direction="left" />,
    nextArrow: <CustomArrow direction="right" />,
  };

  return (
    <div className='bg-meta w-full pb-48 text-silver-1'>
      <div className='grid grid-cols-1 gap-3'>
  
        <Slider {...settings} className='z-0'>
        {projectImages.map((media, index) => (
            media.endsWith('.mp4') ? (
              <video key={index} src={media} alt={`Video`} className='w-fit shadow-gentle rounded-2xl' autoPlay muted loop playsInline />
            ) : (
              <img key={index} src={media} alt={`Image`} className='w-fit shadow-gentle rounded-2xl' />
            )
          ))}
        </Slider>
        <div className='flex xl:justify-between w-full mt-10 flex-col xl:flex-row items-center'>
          <div className='text-silver-1 font-medium text-2xl lg:text-3xl xl:text-left text-center'>
            {projectName}
            <span className='font-normal text-silver-0 text-xl'>{projectSubtitle}</span>
          </div>
          <div className='xl:justify-end justify-center flex flex-wrap gap-4 text-sm xl:pt-0 pt-4 text-silver-0'>
  <div className='py-2 px-4 rounded-full shadow-gentle'>{projectDate}</div>
  {projectType && <div className='py-2 px-4 rounded-full shadow-gentle'>{projectType}</div>}
  {projectLink && (
  <a
    href={projectLink}
    target="_blank"
    rel="noopener noreferrer"
    className="py-2 px-4 rounded-full shadow-gentle hover:text-silver-1"
  >
    {projectLinkDesc}
    <span className="text-silver-0 animate-pulse"> →</span>
  </a>
)}

</div>

        </div>
        <div className='grid grid-cols-1 gap-4 mt-4 lg:grid-cols-3'>
          <div className='bg-none shadow-gentle rounded-2xl flex justify-center items-center py-12 px-8 flex-col relative'>
            <p className='text-silver-0 text-md absolute bottom-4'>Skills</p>
            <p className='text-lg pb-4 text-center'>{skills}</p>
          </div>
          <div className='bg-none shadow-gentle rounded-2xl flex justify-center items-center py-12 px-8 flex-col relative'>
            <p className='text-silver-0 text-md absolute bottom-4'>Deliverables</p>
            <p className='text-lg pb-4 text-center'>{deliverables}</p>
          </div>
          <div className='shadow-gentle rounded-2xl flex justify-center items-center py-12 px-8 flex-col relative'>
            <p className='text-silver-0 text-md absolute bottom-4'>Tools</p>
            <div className='flex gap-3 pb-4 justify-center flex-wrap'>
              {tools.map((tool, index) => (
                <img key={index} src={`/img/icon-${tool.toLowerCase()}.png`} alt={`${tool} Icon`} className='w-10 drop-shadow-md' />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='shadow-gentle rounded-2xl flex justify-center items-center py-12 flex-col mt-4 relative'>
        <p className='text-silver-0 text-md absolute bottom-4'>Story</p>
        <p className='text-lg mx-12 pb-4 text-justify'>{story}</p>
      </div>
    </div>
  );
}

export default Portfolio;
